import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const features = [
  {
    name: "Швейный цех",
    description:
      "Изготавливаем оптом одежду из трикотажа, костюмы, экосумки, куртки и жакет",
  },
  {
    name: "Принт и вышивка",
    description: "Печатаем на текстиле и делаем машинную вышивку.",
  },
  { name: "Вязальный цех", description: "" },
];

export default function Service() {
  const { bg } = useStaticQuery(graphql`
    query {
      bg: allSanityService {
        nodes {
          name
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <div className="bg-white">
      <div aria-hidden="true" className="relative">
        <GatsbyImage
          className="w-full h-full object-center object-cover sm:w-full sm:h-full"
          image={getImage(bg.nodes[0].image.asset)}
          alt={bg.nodes[0].name}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-white" />
      </div>

      <div className="relative -mt-12 max-w-7xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
          <h2 className="text-3xl font-light tracking-tight text-gray-900 sm:text-4xl uppercase">
            Наши услуги
          </h2>
          <p className="mt-4 text-gray-500">
            По желанию заказчиков мы можем производить самые различные виды
            трикотажной и текстильной продукции. Мы экспортируем свои продукции
            в Украину, Россию, Казахстан и Польшу.
          </p>
        </div>

        <dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="border-t border-gray-200 pt-4">
              <dt className="font-medium text-gray-900">{feature.name}</dt>
              <dd className="mt-2 text-sm text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
