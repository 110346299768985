import React from "react";
import { BadgeCheckIcon } from "@heroicons/react/outline";

const features = [
  {
    name: "100% гарантия качества",
    description:
      "Наша трикотажная фабрика оснащена мощным современным оборудованием, которое позволяет выпускать качественную одежду в больших объемах за короткий срок",
    icon: BadgeCheckIcon,
  },
  {
    name: "Трикотаж от производителя",
    description:
      "Производим мужскую, женскую и детскую одежду из трикотажа любых фасонов и размеров",
    icon: BadgeCheckIcon,
  },
  {
    name: "Доступные цены",
    description: "Приятные условия сотрудничества и низкие цены",
    icon: BadgeCheckIcon,
  },
];

export default function Feature() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-10">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-yellow-500 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
