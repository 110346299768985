import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Hero({}) {
  const { hero } = useStaticQuery(graphql`
    query {
      hero: allSanityHero {
        nodes {
          id
          image {
            asset {
              gatsbyImageData(width: 500)
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <div className="relative bg-gray-50 overflow-hidden ">
        <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
            <div className="sm:max-w-lg">
              <h1 className="text-4xl font-light tracking-tight text-gray-900 sm:text-7xl uppercase">
                Трикотажная фабрика{" "}
              </h1>
              <p className="mt-4 font-light md:text-4xl uppercase">
                <span className="sm:text-3xl">Элегантность</span>{" "}
                <span className="sm:text-4xl">удобство</span>{" "}
                <span className="sm:text-5xl">качество</span>
              </p>
            </div>
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
                >
                  <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[0].image.asset)}
                            alt={hero.nodes[0].name}
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[1].image.asset)}
                            alt={hero.nodes[1].name}
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[2].image.asset)}
                            alt={hero.nodes[2].name}
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[3].image.asset)}
                            alt={hero.nodes[3].name}
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[4].image.asset)}
                            alt={hero.nodes[4].name}
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[5].image.asset)}
                            alt={hero.nodes[5].name}
                          />
                        </div>
                        <div className="w-44 h-64 rounded-lg overflow-hidden">
                          <GatsbyImage
                            className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                            image={getImage(hero.nodes[6].image.asset)}
                            alt={hero.nodes[6].name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Link
                  to="/contact"
                  className="inline-block text-center bg-yellow-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-yellow-700"
                >
                  Написать
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
