import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";

export default function Promo() {
  const { products, bg } = useStaticQuery(graphql`
    query {
      products: allSanityProduct {
        nodes {
          id
          name
          slug {
            current
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      bg: allSanityService {
        nodes {
          name
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <div className="relative bg-white pb-10">
      {/* Background image and overlap */}
      <div
        aria-hidden="true"
        className="hidden absolute inset-0 sm:flex sm:flex-col"
      >
        <div className="flex-1 relative w-full bg-gray-800">
          <div className="absolute inset-0 overflow-hidden">
            <GatsbyImage
              className="w-full h-full object-center object-cover sm:w-full sm:h-full"
              image={getImage(bg.nodes[1].image.asset)}
              alt={bg.nodes[1].name}
            />
          </div>
          <div className="absolute inset-0 bg-gray-900 opacity-50" />
        </div>
        <div className="w-full bg-white h-32 md:h-40 lg:h-48" />
      </div>

      <div className="relative max-w-3xl mx-auto pb-96 px-4 text-center sm:pb-0 sm:px-6 lg:px-8">
        {/* Background image and overlap */}
        <div
          aria-hidden="true"
          className="absolute inset-0 flex flex-col sm:hidden"
        >
          <div className="flex-1 relative w-full bg-gray-800">
            <div className="absolute inset-0 overflow-hidden">
              <GatsbyImage
                className="w-full h-full object-center object-cover"
                image={getImage(bg.nodes[1].image.asset)}
                alt={bg.nodes[1].name}
              />
            </div>
            <div className="absolute inset-0 bg-gray-900 opacity-50" />
          </div>
          <div className="w-full bg-white h-48" />
        </div>
        <div className="relative py-32">
          <h1 className="text-4xl tracking-tight text-white sm:text-5xl md:text-5xl uppercase font-light">
            Наша продукция
          </h1>
          <p className="mt-4 text-white text-2xl">
            Полный цикл производства широкого ассортимента трикотажных изделий
            высокого качества
          </p>
          <div className="mt-4 sm:mt-6">
            <Link
              to="/woman"
              className="inline-block text-center bg-yellow-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-yellow-700"
            >
              Заказать
            </Link>
          </div>
        </div>
      </div>

      <section
        aria-labelledby="collection-heading"
        className="-mt-96 relative sm:mt-0"
      >
        <h2 id="collection-heading" className="sr-only">
          Collections
        </h2>
        <div className="max-w-md mx-auto grid grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:px-6 sm:grid-cols-3 sm:gap-y-0 sm:gap-x-6 lg:px-8 lg:gap-x-8">
          {products.nodes.map((product) => (
            <div
              key={product.id}
              className="group relative h-96 bg-white rounded-lg shadow-xl sm:aspect-w-4 sm:aspect-h-5"
            >
              <div>
                <div
                  aria-hidden="true"
                  className="absolute inset-0 rounded-lg overflow-hidden"
                >
                  <div className="absolute inset-0 overflow-hidden group-hover:opacity-75">
                    <GatsbyImage
                      className="w-full h-full object-center object-cover sm:w-full sm:h-full"
                      image={getImage(product.image.asset)}
                      alt={product.name}
                    />
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50" />
                </div>
                <div className="absolute inset-0 rounded-lg p-6 flex items-end">
                  <div>
                    <p aria-hidden="true" className="text-sm text-white">
                      Посмотреть коллекцию
                    </p>
                    <h3 className="mt-1 font-semibold text-white">
                      <Link to={`/${product.slug.current}`}>
                        <span className="absolute inset-0" />
                        {product.name}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
