import * as React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero-section";
import SectionOne from "../components/home-sections/section-one";
import Feature from "../components/home-sections/feature";
import Promo from "../components/promo";
import Service from "../components/home-sections/service";

export default function IndexPage({ data, pageContext }) {
  return (
    <Layout
      title="Главная"
      description="Трикотажная фабрика IMKON. Элегантность, удобство, качество"
    >
      <Hero />
      <Promo />
      <Feature />
      <Service />
      <SectionOne />
    </Layout>
  );
}
